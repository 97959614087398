import {
  LeaderboardListItemType,
  LeaderboardUserRankType,
  ResourceType,
} from '@xborglabs/ui-shared';
import { t } from 'i18next';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import { P, match } from 'ts-pattern';

import { Images } from '@/assets/imgs';
import { LeaderboardList, LeaderboardRow } from '@/modules/leaderboard';
import { LeaderboardListError } from '@/modules/shared/components/Template/Errors';

type LeaderboardProps = {
  data?: LeaderboardListItemType[];
  isError: boolean;
  isLoading: boolean;
  refetch: () => void;
  userRank?: LeaderboardUserRankType;
  resourceType?: ResourceType;
};

export const Leaderboard = ({
  data,
  isError,
  isLoading,
  refetch,
  userRank,
  resourceType,
}: LeaderboardProps) => {
  const { t } = useTranslation(['errors']);

  return match({
    data: data,
    isError,
    isLoading,
  })
    .with(
      {
        data: P.any,
        isLoading: true,
      },
      () => <LeaderBoardPending />,
    )
    .with(
      {
        isError: true,
      },
      () => (
        <LeaderBoardError
          rejectedMessage={t('errors:globalErrors.leaderboard_list_rejected')}
          onReload={refetch}
        />
      ),
    )
    .with(
      {
        data: P.not(P.nullish),
        isError: false,
        isLoading: false,
      },
      () => (
        <div className="community-leaderboard expand">
          <LeaderboardList
            list={data}
            userrank={userRank}
            emptyMessage={t('errors:globalErrors.leaderboard_list_empty')}
            onReload={refetch}
            resourceType={resourceType}
          />
        </div>
      ),
    )
    .otherwise(() => {
      return null;
    });
};

function LeaderBoardPending() {
  return (
    <div className="list-container">
      <div className="flex middle between leaderboard-list-head">
        <div className="leaderboard-list-title">
          <Skeleton width={100} />
        </div>

        <div className="flex middle">
          <Image
            src={Images.points.points_blue}
            alt=""
            width={20}
            height={32}
            className="object-fit-contain"
          />
          <span>{t('globals:xp')}</span>
        </div>
      </div>
      <div className="list flex column">
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
        <LeaderboardRow />
      </div>
    </div>
  );
}

function LeaderBoardError({
  rejectedMessage,
  onReload,
}: {
  rejectedMessage: string;
  onReload: () => void;
}) {
  return <LeaderboardListError text={rejectedMessage} onReload={onReload} />;
}
