import {
  LeaderboardListItemType,
  getDisplayName,
  nFormatter,
} from '@xborglabs/ui-shared';
import { useTranslation } from 'next-i18next';
import Skeleton from 'react-loading-skeleton';

export type LeaderboardRowProps = {
  data?: LeaderboardListItemType;
};

export const LeaderboardRow = ({ data }: LeaderboardRowProps) => {
  const { t } = useTranslation(['globals']);

  return (
    <div
      className={`leaderboard-list-row flex middle list-row-${data?.rank ?? 0}`}
    >
      <div
        className={`index index-${
          data?.rank ?? 0
        } flex middle center lexend-heading-xxs`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="32"
          viewBox="0 0 28 32"
          fill="none"
        >
          <path
            d="M10 2.3094C11.9521 1.18234 12.9282 0.618802 14 0.618802C15.0718 0.618802 16.0479 1.18234 18 2.3094L23.8564 5.6906C25.8085 6.81767 26.7846 7.3812 27.3205 8.3094C27.8564 9.2376 27.8564 10.3647 27.8564 12.6188V19.3812C27.8564 21.6353 27.8564 22.7624 27.3205 23.6906C26.7846 24.6188 25.8085 25.1823 23.8564 26.3094L18 29.6906C16.0479 30.8177 15.0718 31.3812 14 31.3812C12.9282 31.3812 11.9521 30.8177 10 29.6906L4.14359 26.3094C2.19146 25.1823 1.21539 24.6188 0.679492 23.6906C0.143594 22.7624 0.143594 21.6353 0.143594 19.3812V12.6188C0.143594 10.3647 0.143594 9.2376 0.679492 8.3094C1.21539 7.3812 2.19146 6.81767 4.14359 5.6906L10 2.3094Z"
            fill="white"
            fillOpacity="0.04"
          />
          <path
            d="M10.25 2.74241C11.2345 2.17402 11.942 1.76617 12.5364 1.4969C13.1208 1.23217 13.5601 1.1188 14 1.1188C14.4399 1.1188 14.8792 1.23217 15.4636 1.4969C16.058 1.76617 16.7655 2.17402 17.75 2.74241L23.6064 6.12361C24.5909 6.69201 25.2979 7.10082 25.8283 7.48097C26.3497 7.85471 26.6675 8.1784 26.8875 8.5594C27.1075 8.9404 27.2289 9.37746 27.2918 10.0159C27.3558 10.6653 27.3564 11.482 27.3564 12.6188V19.3812C27.3564 20.518 27.3558 21.3347 27.2918 21.9841C27.2289 22.6225 27.1075 23.0596 26.8875 23.4406C26.6675 23.8216 26.3497 24.1453 25.8283 24.519C25.2979 24.8992 24.5909 25.308 23.6064 25.8764L17.75 29.2576C16.7655 29.826 16.058 30.2338 15.4636 30.5031C14.8792 30.7678 14.4399 30.8812 14 30.8812C13.5601 30.8812 13.1208 30.7678 12.5364 30.5031C11.942 30.2338 11.2345 29.826 10.25 29.2576L4.39359 25.8764C3.4091 25.308 2.70213 24.8992 2.17173 24.519C1.65026 24.1453 1.33248 23.8216 1.1125 23.4406C0.892534 23.0596 0.771106 22.6225 0.708169 21.9841C0.644153 21.3347 0.643594 20.518 0.643594 19.3812V12.6188C0.643594 11.482 0.644153 10.6653 0.708169 10.0159C0.771106 9.37746 0.892534 8.9404 1.1125 8.5594C1.33248 8.1784 1.65026 7.85471 2.17173 7.48097C2.70213 7.10082 3.4091 6.69201 4.39359 6.12361L10.25 2.74241Z"
            stroke="url(#paint0_linear_14054_28805)"
            strokeOpacity="0.16"
          />
          <defs>
            <linearGradient
              id="paint0_linear_14054_28805"
              x1="29"
              y1="1"
              x2="15"
              y2="15.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        {data ? data.rank ?? t('globals:na') : <Skeleton />}
      </div>

      <div className="username expand lexend-body-s font-bold">
        {data ? (
          getDisplayName(
            data.user.userId,
            data.user.handle,
            data.user.displayName,
          )
        ) : (
          <Skeleton />
        )}
      </div>

      <div className="points lexend-body-s font-bold flex middle">
        <span>{`${nFormatter(data?.balance ?? 0, 1)}`}</span>
      </div>
    </div>
  );
};
