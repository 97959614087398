import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import { Images } from '@/assets/imgs';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

import classes from './ListError.module.scss';

interface ListErrorProps {
  text: string;
  image: JSX.Element;
  onReload?: () => void;
  btnLabel?: string;
  hasVideo?: boolean;
}

export const ListError = ({
  text,
  onReload,
  btnLabel,
  image,
  hasVideo = false,
}: ListErrorProps) => {
  const { t } = useTranslation(['globals']);
  return (
    <div className={`${classes.list_error} flex column middle center`}>
      <div className={`${classes.container} flex column`}>
        {image}

        {hasVideo && (
          <video
            className={classes.video}
            src="/videos/lightning.webm"
            autoPlay
            loop
            muted
          />
        )}
      </div>

      <div className={`${classes.text} lexend-body-m`}>{text}</div>

      {onReload && (
        <Button variant={BUTTON_VARIANT.RED} onClick={onReload}>
          {btnLabel ?? t('globals:reload')}
        </Button>
      )}
    </div>
  );
};

export const questErrorImage = (
  <Image
    src={Images.quests.empty}
    width={471}
    height={246}
    alt=""
    className={`${classes.error_image} object-fit-contain`}
  />
);

export const eventsErrorImage = (
  <Image
    src={Images.events.empty}
    width={546}
    height={285}
    alt=""
    className={`${classes.error_image} object-fit-contain`}
  />
);

export const leaderboardErrorImage = (
  <Image
    src={Images.leaderboard.empty}
    width={604}
    height={268}
    alt=""
    className={`${classes.error_image} object-fit-contain`}
  />
);

export const inventoryErrorImage = (
  <Image
    src="/images/inventory/bag.png"
    width={295}
    height={295}
    alt="Inventory bag"
    className={`${classes.error_image} object-fit-contain`}
  />
);
