import { useTranslation } from 'next-i18next';

export const EmptyLeaderboardList = () => {
  const { t } = useTranslation(['errors']);

  return (
    <div className="empty-leaderboard-list lexend-body-xs1">
      {t('errors:globalErrors.leaderboard_list_empty')}
    </div>
  );
};
