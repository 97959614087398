import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Images } from '@/assets/imgs';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

interface LeaderboardListErrorProps {
  text: string;
  onReload?: () => void;
}

export const LeaderboardListError = ({
  text,
  onReload,
}: LeaderboardListErrorProps) => {
  const { t } = useTranslation(['globals']);
  return (
    <div className="leaderboard-list-error flex column middle">
      <div className="images-container flex middle center">
        <Image
          src={Images.leaderboard.silver}
          width={420}
          height={376}
          alt=""
        />
        <Image src={Images.leaderboard.gold} width={453} height={376} alt="" />
        <Image
          src={Images.leaderboard.bronze}
          width={341}
          height={376}
          alt=""
        />

        <video
          className="video"
          src="/videos/lightning.webm"
          autoPlay
          loop
          muted
        />
      </div>

      <div className="lexend-body-m">{text}</div>

      {onReload && (
        <Button
          variant={BUTTON_VARIANT.TRANSPARENT}
          onClick={(e) => {
            e.preventDefault();
            onReload();
          }}
        >
          {t('globals:reload')}
        </Button>
      )}
    </div>
  );
};
